<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<script>

//import WebBuilder from "@/components/personalizations/webBuilder";
import CustomWebBuilder from "@/components/personalizations/customWebBuilder";
import CustomJsonBuilder from "@/components/personalizations/customJsonBuilder";
import CustomJsBuilder from "@/components/personalizations/customJSBuilder";
//import Templates from  "@/components/personalizations/templates";
import EmptyList from "@/components/widgets/empty_list";
import Drawer from "vue-simple-drawer";
import { required } from "vuelidate/lib/validators";
import Store from "@/components/personalizations/templates-store.vue"
import TemplateActivity from "@/components/personalizations/template-activity"
import TemplateAICreator from "@/components/personalizations/template-ai-creator"

import {
  projectMethods,
  contentMethods,
  templateMethods,
  userMethods,
} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      showVariantTemplate: false,
      showNewTemplate: false,
      showCustomVariantTemplate:false,
      html:'',
      variant:{},
      isUpdate:false,
      openRightDrawer:false,
      empty_config:{
        "title" : this.$t('personalizations.variants_empty'),
        "subtitle" : this.$t('personalizations.variants_empty_subtitle'),
        "buttonText" : this.$t('personalizations.new_variant'),
        "secondButtonText" :  this.$t('personalizations.copy_experience'),
        icon: "fa-cubes"
      },
      template:{},
      submitted:false,
      companyPicture: null,
      showPreviewURL:false,
      url:null,
      items:[],
      showTemplatesAvailable: false,
      showTemplateActivity: false,
      selectedVariant:null,
      viewport :'desktop',
      mode: 'design',
      showCopyFromOtherExperienceModal: false,
      experienceVariants: [],
      showTemplateAICreator: false
    };
  },
  components: { Drawer, CustomWebBuilder, EmptyList, CustomJsonBuilder, Store, CustomJsBuilder, TemplateActivity, TemplateAICreator},
  validations: {
    template: {
        name: { required},
    }
  },
  mounted() {
    this.loadItems()
    const user = JSON.parse(localStorage.getItem("auth.currentUser")).user;

    if(user && user.company){
      this.loadCompany(user);
    }

    const project = localStorage.getItem("current_project");
    this.url = localStorage.getItem(`site_url_${project}`);

  },
    methods: {
      ...projectMethods,
      ...contentMethods,
      ...templateMethods,
      ...userMethods,

      loadItems(){
        
				const params = {
					q: `whereObj={"project":"${localStorage.getItem('current_project')}", "data.active":1}&limit=50`
				}

        this.getItems(params).then((res)=>{
					this.items = res.data;
        }).catch(()=>{
					this.$notify({ type: 'error', text: this.$t('items.list_get_error'),title:  this.$t('items.title') });
				}).finally(()=>{
					
				})
		},

      rightDrawer() {
          this.openRightDrawer = !this.openRightDrawer;
      },

      onChangeStatusClicked(index){
        const status = this.variants[index].status == 1 ? 0 : 1;

        const variant = this.variants[index];
        variant.status = status;
        
        this.$set(this.variants, index, variant);
      },
      
      onNewVariantClicked(){
        this.isUpdate = false;
        this.variant = {},
        this.variant.name = `Variant ${this.variants.length + 1}`,
        this.variant.ratio = 0;
        this.variant.status = 1;

        if(this.channel == 'API'){
          //this.openRightDrawer = true;
          }else{
            //this.openRightDrawer = true;
          }
          this.showTemplatesAvailable = true;
      },

      onConfirmVariantClicked(save=false){
        if(this.channel == 'web'){
          if(this.type=='custom_code'){
            this.variant.templateValue = this.$refs.customJsBuilder.getTemplatesValues();  
          }else{
            this.variant.templateValue = this.$refs.customWebBuilder.getTemplatesValues()  
          }
          
        }else{
          this.variant.templateValue = this.$refs.customJsonBuilder.getTemplatesValues()
        }
        
        if(this.personalizationId){
          const payload={
              personalizationId: this.personalizationId,
              variant: this.variant,
              experience: this.experienceId 
            }
          if(this.experienceId && this.variant._id){
            let loader = this.$loading.show();
            this.updateVariant(payload).then(()=>{
              this.$notify({ type: 'success', text: this.$t('personalizations.update_personalization_experience_variant_success'),title:  this.$t('personalizations.title') });
            }).catch(()=>{
              this.$notify({ type: 'error', text: this.$t('personalizations.update_personalization_experience_variant_error'),title:  this.$t('personalizations.title') });
            }).finally(()=>{
              loader.hide();
            })
          }else if(this.experienceId){
            let loader = this.$loading.show();
            this.createVariant(payload).then(()=>{
              this.$notify({ type: 'success', text: this.$t('personalizations.create_personalization_experience_variant_success'),title:  this.$t('personalizations.title') });
            }).catch(()=>{
              this.$notify({ type: 'error', text: this.$t('personalizations.create_personalization_experience_variant_error'),title:  this.$t('personalizations.title') });
            }).finally(()=>{
              loader.hide();
            })
          }
        }
        if(!this.isUpdate){
          this.variants.push(this.variant);
        }
        
        if(!save)
          this.showCustomVariantTemplate = false;
      },

      onEditVariantClicked(variant){
        if(this.winner && this.winner.winner !='' && variant._id != this.winner.winner)
          return;

        this.isUpdate = true;
        this.variant = variant;
        
        this.showCustomVariantTemplate = true; 

        let loader = this.$loading.show();
        setTimeout(()=>{
          if(this.channel == 'web'){
            if(this.type=='custom_code'){
              this.$refs.customJsBuilder.setTemplateValue(variant.templateValue);  
            }else{
              this.$refs.customWebBuilder.setTemplateValue(variant.templateValue);
            }
          }
          else
            this.$refs.customJsonBuilder.setTemplateValue(variant.templateValue);
          loader.hide();
        },2000)
        
      },

      onRemoveVariantClicked(variant){
        this.variants.splice(variant,1);
      },

      onCloneVariantClicked(variant){
        let v = Object.assign({}, variant);
        v.name = `Variant ${this.variants.length + 1}`;
        v.ratio = 0;
        delete v._id;

        if(this.experienceId && this.personalizationId){
          const payload={
              personalizationId: this.personalizationId,
              variant: v,
              experience: this.experienceId 
          }
          let loader = this.$loading.show();
            this.createVariant(payload).then(()=>{
              this.$notify({ type: 'success', text: this.$t('personalizations.create_personalization_experience_variant_success'),title:  this.$t('personalizations.title') });
              this.variants.push(v);
            }).catch(()=>{
              this.$notify({ type: 'error', text: this.$t('personalizations.create_personalization_experience_variant_error'),title:  this.$t('personalizations.title') });
            }).finally(()=>{
              loader.hide();
            })
        }else{
          this.variants.push(v);
        }
        
      },

      onTemplateClicked(template){
        if(template.using_ai){
          this.showTemplatesAvailable = false;
          this.showTemplateAICreator = true;
          return;
        }

        this.showTemplatesAvailable = false;
        this.showCustomVariantTemplate = true;
        
        let loader = this.$loading.show();
        setTimeout(()=>{
          if(this.channel == 'web')
          if(this.type=='custom_code'){
              this.$refs.customJsBuilder.setTemplateValue(template.value);  
            }else{
              this.$refs.customWebBuilder.setTemplateValue(template.value);
            }
          else
            this.$refs.customJsonBuilder.setTemplateValue(template.value);
          loader.hide();
        },2000)
      },
      onSaveAsTemplateClicked(){
        this.template = {
          type: this.channel == 'API' ? this.channel : this.type
        }

        this.submitted = false
        this.showNewTemplate = true;
      },
      onConfirmNewTemplateClicked(){
        this.submitted = true;
        
        this.$v.$touch();

        if (this.$v.$invalid) {
          return;
        }

        if(this.channel == 'web'){
          if(this.type == 'custom_code'){
            this.template.value = this.$refs.customJsBuilder.getTemplatesValues();
          }else{
            this.template.value = this.$refs.customWebBuilder.getTemplatesValues();
          }
        }
        else
          this.template.value = this.$refs.customJsonBuilder.getTemplatesValues();
        
        this.template.screenshot = this.companyPicture;
        this.template.project = localStorage.getItem('current_project');
        let loader = this.$loading.show();
        this.createTemplate(this.template).then(()=>{
          this.$notify({ type: 'success', text: "Se ha creado el template correctamente",title:  this.$t('personalizations.title') });
          this.showNewTemplate = false;
        }).catch(()=>{
          this.$notify({ type: 'error', text: "Se produjo un error al intentar crear el template",title:  this.$t('personalizations.title') });
        }).finally(()=>{
          loader.hide();
        });
         
      },
      loadCompany(user) {
      
			this.getCompany(user.company._id).then((res)=>{
				if(res&& res.data){
					this.companyPicture = this.company.logo || "https://uploads-ssl.webflow.com/62a73c358583fc722d325842/62a73d629590967696df6168_logo.svg";
			}
			}).catch(()=>{
        this.companyPicture = "https://uploads-ssl.webflow.com/62a73c358583fc722d325842/62a73d629590967696df6168_logo.svg"
				//this.$notify({ type: 'error', text: this.$t("company.get_company_error"),title: this.$t("company.title") });
			}).finally(()=>{
				
			});
    },
    onPreviewClicked(variant){
      this.variant = variant;
      this.showPreviewURL = true;
    },
    onConfirmPreViewClicked(){
      const project = localStorage.getItem("current_project");
      localStorage.setItem(`site_url_${project}`,this.url);
      this.showPreviewPanel = false;
      
      const URL = `${this.url}?gsIncludeDraft=true`; 
      window.open(URL, '_blank'); 
    },
    onVariantRationChanged(index){
        
        const variant = this.variants[index];
        variant.ratio = this.variants[index].ratio;
        
        this.$set(this.variants, index, variant);
      
        if(this.variants.length == 2) {
          const auxIndex = index == 1 ? 0 : 1 
          this.variants[auxIndex].ratio = 100 - this.variants[index].ratio;

          const variant = this.variants[auxIndex];
          variant.ratio = 100 - this.variants[index].ratio;
          
          this.$set(this.variants, auxIndex, variant);
        }
    },
    onActivityClicked(variant){

      this.selectedVariant = {
        variantId: variant._id,
        personalizationId: this.personalizationId,
        experienceId: this.experienceId
      };
      this.showTemplateActivity = true;
    },
    onVersionSelected(variantVersion){
      this.isUpdate = true;
        this.variant = this.variants.filter(v=> v._id == variantVersion.entity_id)[0];
        this.variant.templateValue = variantVersion.value.templateValue;
        this.showTemplateActivity = false;
        this.showCustomVariantTemplate = true; 

        let loader = this.$loading.show();
        setTimeout(()=>{
          if(this.channel == 'web'){
            if(this.type=='custom_code'){
              this.$refs.customJsBuilder.setTemplateValue(this.variant.templateValue);  
            }else{
              this.$refs.customWebBuilder.setTemplateValue(this.variant.templateValue);
            }
          }
          else
            this.$refs.customJsonBuilder.setTemplateValue(this.variant.templateValue);
          loader.hide();
        },2000)
    },
    setViewport(viewport){
      this.viewport= viewport;
      this.$refs.customWebBuilder.onResolutionChanged(viewport);
    },
    setMode(mode){
      this.mode = mode;
      this.$refs.customWebBuilder.onSetMode(mode);
    },
    onCopyFromOtherExperienceClicked(){
      this.showCopyFromOtherExperienceModal = true;

      this.loadExperienceVariants();

    },
    onConfirmCopyFromAnotherExperienceClicked(variant){

      this.isUpdate = false;
      this.variant = {},
      this.variant.name = `Variant ${this.variants.length + 1}`,
      this.variant.ratio = 0;
      this.variant.status = 1;

      let loader = this.$loading.show();
      this.showCopyFromOtherExperienceModal = false;
      this.showCustomVariantTemplate = true;


      setTimeout(()=>{
          if(this.channel == 'web')
            if(this.type=='custom_code'){
              this.$refs.customJsBuilder.setTemplateValue(variant.templateValue);  
            }else{
              this.$refs.customWebBuilder.setTemplateValue(variant.templateValue);
            }
          else
            this.$refs.customJsonBuilder.setTemplateValue(variant.templateValue);
          loader.hide();
        },2000)
    },

    loadExperienceVariants(){
      let loader = this.$loading.show();

      const params = {
        q : `whereObj={"project":"${localStorage.getItem('current_project')}", "type": "${this.type}"}`
      }
      this.getContents(params).then((contents)=>{
        if(contents&& contents.data){
          this.experienceVariants = contents.data.filter(c=>c._id!=this.personalizationId).flatMap(content => 
            content.targets.flatMap(experience => 
              experience.variants.map(variant => ({
                contentName: content.name,
                experienceName: experience.name,
                variant
              })) 
            )
          ) || [];
        }
      }).finally(()=>{
        loader.hide();
      })
    }
  },
  props: {
    variants: Array,
    channel: String,
    type: String,
    affinity_Types: Array,
    personalizationId: String,
    experienceId: String,
    categories: Array,
    winner:Object,
    pageType: String  
  },
	emits:[],
  watch: {
    type:{},
    node:{
      immediate: true,
      handler(newVal){
        if(newVal && newVal.field){
          this.getTargetFieldByValue(newVal.field,true);

          if(newVal.audience){
            this.onAudienceSelected(newVal,true);
          }
        }
      }
    }
  }
}

 </script>

<template>
    
	<div class="row">
    <div class="text-end col">
      <button type="button" v-on:click="onNewVariantClicked" class="btn btn-sm btn-primary mt-0 mb-3 me-2" v-if="variants.length > 0 && (!winner || !winner.winner ||  winner.winner =='')">+ {{$t('personalizations.new_variant')}}</button>
      <button type="button" v-on:click="onCopyFromOtherExperienceClicked" class="btn btn-sm btn-outline-primary mt-0 mb-3" v-if="variants.length > 0 && (!winner || !winner.winner ||  winner.winner =='')">{{ $t('personalizations.copy_experience')}}</button>
    </div>
    <div class="px-0">
      <EmptyList :config="empty_config" v-if="variants.length == 0" @onButtonClicked="onNewVariantClicked" @onSecondButtonClicked="onCopyFromOtherExperienceClicked" class="mt-3"/>
      <table class="table table align-end mt-3" v-if="variants.length > 0 && winner?.winner && winner.winner !=''">
        <thead class="table-light">
          <tr>
            <th style="width:40px"></th>
            <th>{{$t('personalizations.name')}}</th>
            <th>{{$t('personalizations.impressions')}}</th>
            <th>{{$t('personalizations.convertions')}}</th>
            <th>{{$t('personalizations.convertion_rate')}}</th>
            <th>{{'Reveue'}}</th>
            <th>{{'AOV'}}</th>
            <th>
              <div class="float-end">{{$t("personalizations.status")}}</div>
            </th>
            <th ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(variant) in variants" :key="variant.name">
            <td style="width:40px">
              <i class="bx bxs-trophy font-size-22 me-1 text-primary" v-if="variant._id == winner?.winner"/>
            </td>
            <td class="align-middle" v-on:click="onEditVariantClicked(variant)" :class="{'fw-bold text-decoration-underline interact': variant._id == winner?.winner}">
              {{ variant.name }}
            </td>
            <td class="align-middle">
              {{ variant._id == winner?.winner ? variant.data.samples: '-'}}
            </td>
            <td class="align-middle">
              {{ variant._id == winner?.winner ? variant.data.conversions: '-' }}
            </td>
            <td class="align-middle">
              {{ variant._id == winner?.winner && variant.data.samples > 0 && variant.data.conversions ?  `${((variant.data.conversions/variant.data.samples) * 100).toFixed(1).replace(/[.,]0$/, "")}%`  : '-' }}
            </td>
            <td class="align-middle">
              {{ variant._id == winner?.winner ? variant.data?.revenue ? `$${variant.data.revenue?.toFixed(2)}` : '-' : '-' }}
            </td>
            <td class="align-middle">
              {{ variant._id == winner?.winner ? variant.data?.aov ? `$${variant.data.aov?.toFixed(2)}` : '-' : '-'  }}
            </td>
            <td>
              <div class="float-end">
                <span class="badge font-size-12"
                          :class="{
                          'bg-success': `${variant.status}` == 1,
                          'bg-warning': `${variant.status}` == 0}"
                          v-if="variant._id == winner?.winner">
                            {{ variant.status == 1 ? $t('personalizations.variant_status_active') : $t('personalizations.variant_status_incative') }}
                        </span>
                        <span class="badge font-size-12 bg-danger" v-else>
                            {{$t('personalizations.variant_status_defeated') }}
                        </span>
              </div> 
            </td>
            <td class="text-end align-middle">
              <i class="fas fa-eye text-primary me-2 interact" v-on:click="onPreviewClicked(variant)" v-if="variant._id != null && winner?.winner == variant._id"/>
              <i class="fas fa-play text-primary me-2 interact" v-on:click="onChangeStatusClicked(index)" v-if="variant.status == 0 && winner?.winner == variant._id"/>
              <i class="fas fa-pause text-primary me-2 interact" v-on:click="onChangeStatusClicked(index)" v-if="variant.status == 1 && winner?.winner == variant._id"/>
              
              <b-dropdown
                class="card-drop"
                variant="white"
                right
                toggle-class="p-0"
                menu-class="dropdown-menu-end"
                v-if="winner.winner == variant._id">
                <template v-slot:button-content>
                <i class="mdi mdi-dots-horizontal font-size-18"></i>
                </template>

                <b-dropdown-item @click="onEditVariantClicked(variant)">
                  <i class="fas fa-edit text-success me-1"></i>
                  {{$t('common.edit')}}
                </b-dropdown-item>

                <b-dropdown-item @click="onRemoveVariantClicked(index)">
                  <i class="fas fa-trash-alt text-danger me-1"></i>
                  {{$t('common.delete')}}
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table align-end" v-if="variants.length > 0 && (!winner || !winner.winner ||  winner.winner =='')">
        <thead class="table-light">
          <tr>
            <th>{{$t('personalizations.name')}}</th>
            <th></th>
            <th>
              <div class="float-end"> {{$t('personalizations.variant_allocation')}} </div>
            </th>
            <th v-if="variants.length > 1"><div class="float-end">{{$t('personalizations.experience_variants_winner_percentage_be_winner')}}</div></th>
            <th>
              <div class="float-end">{{$t("personalizations.status")}}</div>
            </th>
            <th ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(variant,index) in variants" :key="variant.name">
            <td class="align-middle fw-bold text-decoration-underline interact" v-on:click="onEditVariantClicked(variant)">
              {{ variant.name }}
            </td>
            <td>
                <b-form-input v-model="variant.ratio" type="range" :min="0" :max="100" step="1" class="w-100" @input="onVariantRationChanged(index)" :disabled="winner && winner!=''" />
            </td>
            <td>
              <div class="d-inline-flex align-items-center float-end">
                %  <b-form-input v-model="variant.ratio" type="number" :min="0" :max="100" step="1" class="form-control form-control-sm" @input="onVariantRationChanged(index)" :disabled="winner && winner!=''"/>
              </div>
            </td>
            <td v-if="variants.length > 1 "> <div class="d-inline-flex align-items-center float-end"> {{ variant.report ? `${variant.report?.probability_to_be_best * 100}%` : '-' }}</div></td>
            <td>
              <div class="float-end">
                <span class="badge font-size-12"
                          :class="{
                          'bg-success': `${variant.status}` == 1,
                          'bg-warning': `${variant.status}` == 0}">
                            {{ variant.status == 1 ? $t('personalizations.variant_status_active') : $t('personalizations.variant_status_incative') }}
                        </span>
              </div> 
            </td>
            <td class="text-end align-middle">
              <i class="fas fa-history text-primary me-2 interact" v-on:click="onActivityClicked(variant)" v-if="variant._id != null"/>
              <i class="fas fa-eye text-primary me-2 interact" v-on:click="onPreviewClicked(variant)" v-if="variant._id != null"/>
              <i class="fas fa-play text-primary me-2 interact" v-on:click="onChangeStatusClicked(index)" v-if="variant.status == 0"/>
              <i class="fas fa-pause text-primary me-2 interact" v-on:click="onChangeStatusClicked(index)" v-if="variant.status == 1"/>
              
              <b-dropdown
                class="card-drop"
                variant="white"
                right
                toggle-class="p-0"
                menu-class="dropdown-menu-end">
                <template v-slot:button-content>
                <i class="mdi mdi-dots-horizontal font-size-18"></i>
                </template>

                <b-dropdown-item @click="onCloneVariantClicked(variant)">
                  <i class="fas fa-clone text-info me-1"></i>
                  {{$t('common.duplicate')}}
                </b-dropdown-item>

                <b-dropdown-item @click="onEditVariantClicked(variant)">
                  <i class="fas fa-edit text-success me-1"></i>
                  {{$t('common.edit')}}
                </b-dropdown-item>

                <b-dropdown-item @click="onRemoveVariantClicked(index)">
                  <i class="fas fa-trash-alt text-danger me-1"></i>
                  {{$t('common.delete')}}
                </b-dropdown-item>
              </b-dropdown>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
    <b-modal
					v-model="showVariantTemplate"
					id="modal-xl"
					size="xl"
					title-class="font-18"
          no-close-on-backdrop 
          scrollable
          @ok.prevent="onConfirmVariantClicked"  :okTitle="personalizationId? $t('personalizations.save_variant'):$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
          <template #modal-title>
            <input class="form-control" v-model="variant.name" style="font-weight: bold;"/>
          </template>
          <WebBuilder ref="webBuilder"/>
      </b-modal>
      <Drawer
        @close="rightDrawer"
        :align="'right'"
        :closeable="true"
        :maskClosable="true"
        :zIndex="1002">
                  <!--<Templates ref="TemplatesComponent" :channel="channel" v-if="openRightDrawer" @selectTemplate="onTemplateClicked" :type="type"></Templates>-->
      </Drawer>

      <b-modal
        v-model="showCustomVariantTemplate"
					id="modal-xl"
          size="xl"
					title-class="font-18"
          no-close-on-backdrop 
          scrollable
          @ok.prevent="onConfirmVariantClicked"  :okTitle=" personalizationId && experienceId ? $t('personalizations.save_variant') : $t('common.confirm')"  :cancelTitle="$t('common.cancel')">
          <template #modal-header>
            
            <div class="left-content">
              <input class="form-control" v-model="variant.name" style="font-weight: bold;     font-weight: bold; width: 185px;"/>
              <div class="btn-group" v-if="channel!='API' && type != 'custom_code'">
                <button type="button" :class="viewport =='desktop' ?'btn btn-primary' : 'btn btn-outline-primary'" v-if="false" @click="setMode('preview')"  >
                  {{$t('personalizations.experience_variant_mode_preview')}}
                </button>
                <button type="button" :class="mode =='design' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setMode('design')" >
                  {{$t('personalizations.experience_variant_mode_design')}}
                </button>
                <button type="button" :class="mode =='code' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setMode('code')" >
                  {{$t('personalizations.experience_variant_mode_advanced')}}
                </button>
              </div>
            </div>
            <div class="center-content" v-if="mode=='design'">
              <div class="btn-group viewport-controls" v-if="channel=='web' && type != 'custom_code'">
                <button type="button" :class="viewport =='desktop' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setViewport('desktop')"  >
                  <i class="mdi mdi-desktop-mac"></i>
                </button>
                <button type="button" :class="viewport =='tablet' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setViewport('tablet')">
                  <i class="mdi mdi-tablet-ipad"></i>
                </button>
                <button type="button" :class="viewport =='mobile' ?'btn btn-primary' : 'btn btn-outline-primary'" @click="setViewport('mobile')">
                  <i class="mdi mdi-cellphone-iphone"></i>
                </button>
              </div>
            </div>
          </template>
          <template #modal-footer>
            <div class="w-100 d-flex justify-content-between">
              <button class="btn btn-outline-primary" @click="onSaveAsTemplateClicked">{{$t('personalizations.save_as_template')}}</button>
              <div class="ml-auto">
                <b-button variant="secondary" class="me-2" @click="showCustomVariantTemplate = false">{{$t('common.cancel')}}</b-button>
                <button class="btn btn-outline-primary me-2" @click="onConfirmVariantClicked(true)" v-if="variant._id">{{$t('common.save')}}</button>
                <b-button variant="primary" class="btn btn-primary" @click.prevent="onConfirmVariantClicked(false)">{{  personalizationId && experienceId ? $t('personalizations.save_variant') : $t('common.confirm')  }}</b-button>
              </div>
            </div>
          </template>
            <CustomWebBuilder ref="customWebBuilder" :items="items" v-if="channel=='web' && type != 'custom_code'" :affinity_Types="affinity_Types" :categories="categories" :variant="variant" :pageType="pageType"/>
            <CustomJsonBuilder ref="customJsonBuilder" v-if="channel=='API' || channel== 'API_SIMPLE'" :items="items" :affinity_Types="affinity_Types" :categories="categories" :pageType="pageType" />
            <CustomJsBuilder ref="customJsBuilder" v-if="channel=='web' && type == 'custom_code'" :categories="categories" :items="items" :affinity_Types="affinity_Types" :pageType="pageType" />
      </b-modal>

      <b-modal
        :title="$t('personalizations.save_as_template')"
        v-model="showNewTemplate"
        title-class="font-18"
        @ok.prevent="onConfirmNewTemplateClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
        <div class="row">
          <div class="mb-3">
            <label for="promotionName">{{$t('personalizations.name')}}*</label>
            <input
                id="promotionName"
                v-model="template.name"
                name="name"
                type="text"
                class="form-control"
                :placeholder="$t('personalizations.name_placeholder')"
                :class="{ 'is-invalid': submitted && $v.template.name.$error}"
            />
            <div v-if="submitted && !$v.template.name.required" class="invalid-feedback">
              {{ $t('personalizations.name_required') }}
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal
        title="Preview"
        v-model="showPreviewURL"
        @ok.prevent="onConfirmPreViewClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
        <div class="row">
          <div class="col">
              <label>URL*</label>
              <input class="form-control" v-model="url"/>
          </div>
        </div>
      </b-modal>
      <b-modal id="templates-modal" size="xl" v-model="showTemplatesAvailable" :title="$t('personalizations.experience_variant_start_with')" hide-footer no-close-on-backdrop>
        <Store :subType="type" :hideSubtypeFilter="true" :hideGoalsFilter="true" :showFromScratch="true" @selectStoreTemplate="onTemplateClicked" :showMyTemplates="true" :type="'personalization'"/>
      </b-modal>
      <b-modal v-model="showTemplateAICreator" title="Generate Template" size="xl" hide-footer>
        <TemplateAICreator />
      </b-modal>
      <b-modal 
        v-model="showTemplateActivity"
        :title="$t('templates.history')"
        hide-footer>
        <TemplateActivity :variant="selectedVariant" @selectVersion="onVersionSelected"/>
      </b-modal>

      <b-modal
        v-model="showCopyFromOtherExperienceModal"
        no-close-on-backdrop 
        scrollable
        title="Copy from Another Experience"
        hide-footer>
        <div>
            <div class="table-responsive">
              <table class="table b-table table-centered mb-0">
                <tbody class="">
                  <tr v-for="(v, index) in experienceVariants" :key="index">
                    <td class="text-start p-0">
                      <div class="d-block">
                        <label class="fw-bold mb-0">{{ v.contentName }}</label>
                        <p class="small">{{ `${v.experienceName} - ${v.variant.name}` }}</p>
                      </div>
                    </td>
                    <td class="text-end interact" v-on:click="onConfirmCopyFromAnotherExperienceClicked(v.variant)">
                      <i class="bx bx-download h4"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="my-5 text-center" v-if="experienceVariants.length == 0">
              <label>No experiences found</label>
            </div>
          </div>
      </b-modal>
  </div>
</template>
<style scoped>
.interact{
    cursor: pointer;
}

.vue-simple-drawer{ 
    min-width: 500px !important;
}

.save-template-button{
  position: absolute;
  left: 10px;
}

.modal-xl{
  --bs-modal-width: 95% !important;
} 
.left-content {
    display: flex;
    align-items: center;
    gap: 10px; /* Optional: Adjust spacing between input and buttons */
}

.center-content {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
}

</style>

<style>
#templates-modal .modal-body {
  background-color: var(--bs-body-bg);
  min-height: 500px;
}

</style>