
<script>
import DropZone from "@/components/widgets/dropZone";
import { Base64 } from 'js-base64';

import {
  contentMethods,
  commonMethods
} from "@/state/helpers";

export default {
  data() {
		return {
      content: null,
      contentHtml: null
    }
  },
  components:{DropZone},
 name: 'TemplateAiCreator',
  props:{
    
  },
  computed:{
    
  },
  watch:{
    
  },
 mounted(){
  
 },
 methods:{
  ...contentMethods,
  ...commonMethods,
  decodeBase64(base64String) {
    try {
      // eslint-disable-next-line no-console
      console.log('daleee');

      return Base64.decode(base64String);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error decoding base64 string:', error);
      return '';
    }
  },
  onDropFileZoneSelected(obj){

  if(obj?.file){
    let loader = this.$loading.show();

    this.uploadMedia(obj?.file).then((res) => {
      
      if(res.data.url){
        const payload = {
          imageUrl: res.data.url,
        }
        this.generateContentFromImage(payload).then((res) => {
          this.content = res;
          this.contentHtml = this.decodeBase64(res.exportedHtml);
          // eslint-disable-next-line no-console
          console.log(this.contentHtml);
          try{
            setTimeout(()=>{
              const previewFrame = this.$refs.previewFrame;
              previewFrame?.setAttribute('srcdoc', this.contentHtml);
            },1000)
          }catch(ex){
            // eslint-disable-next-line no-console
            console.log(ex);
          }
        }).catch(() => {
          this.$notify({ type: 'error', text: 'There was an error generating the template',title: 'Generate Template' });
        }).finally(()=>{
          loader.hide()
        })
      }else{
        this.$notify({ type: 'error', text: 'There was an error uploading the file',title: 'Generate Template' });
        loader.hide();
      }
    }).catch(() => {
      this.$notify({ type: 'error', text: 'There was an error uploading the file',title: 'Generate Template' });
      loader.hide();
    })
  }
  },
 }
}
</script>

<template>
  <div class="row">
    <label class="template-creator-label">
      Crea tu contenido en segundos: sube una imagen y deja que la IA haga la magia.
    </label>
    <div class="px-4 py-2" v-if="!content">
      <DropZone ref="dropZone" :accept="'image/png, image/jpeg, image/jpg'" @onFile="onDropFileZoneSelected" :field="{}" :active="false" v-if="!content"/>
    </div>
    <div class="mt-2" style="height: 500px" v-else>
      <iframe ref="previewFrame" style="width: 100%; height: 500px; transform: scale(0.6); transform-origin: top;" /> 
    </div>
  </div>
</template>

<style>

</style>

